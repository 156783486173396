.requestdemo_div_boxes_divs_holder{
    height:16vw;
    width: 100%;
    background-color: red;
    position: absolute;
    bottom: 0%;
    display: flex;
}
.you_run_profitable{
    width: 50%;
    height: 100%;
    text-align: center;
    color: #FFFFFF;
    font-weight: 800;
    font-size: 1.5vw;
}
.backgroundimage_contactuse_holder_div_requestdemo {
    height: 100%;
    width: 60%;
    background-color: #FFFFFF;
    position: relative;
}

.backgroundimage_contactuse_holder_div_requestdemo {
    background-size: 100% 100% ;
}

@media screen and (max-width: 1024px) {
    .you_run_profitable{
        font-size: 3vw;
    }
    .backgroundimage_contactuse_holder_div_requestdemo{
        height: 90vw;
        width: 100%;
        background-size: 100% 170vw;
    }
}


